// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import { MotionConfig, motion, AnimatePresence } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import {
    getDocument,
    GlobalWorkerOptions,
    getPdfFilenameFromUrl,
} from "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.2.67/+esm"
import { addPropertyControls, ControlType } from "framer"
/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
GlobalWorkerOptions.workerSrc =
    "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.2.67/build/pdf.worker.mjs" || ""

export default function Pdfviewer(props) {
    const {
        file,
        pageNumber,
        scale,
        buttonText,
        buttonStyles,
        modalStyles,
        customFileName,
    } = props
    const [modalOpen, setModalOpen] = useState(false)
    const canvasRef = useRef(null)
    const [totalPages, setTotalPages] = useState(null)
    const [pageRendering, setPageRendering] = useState(false)
    const [pageNumPending, setpageNumPending] = useState(null)
    const [pageNum, setpageNum] = useState(pageNumber)
    const [pageScale, setPageScale] = useState(scale)
    const [downloadURL, setdownloadURL] = useState(null)

    const renderPage = (pdf) => {
        const canvas = canvasRef.current
        const context = canvas.getContext("2d")
        if (pageRendering) {
            // Check if other page is rendering
            setpageNumPending(pageNum) // Cache waited page number until previous page rendering completed
        } else {
            setPageRendering(true)
            // Using promise to fetch the page
            pdf?.getPage(pageNum).then((page) => {
                let viewport = page.getViewport({ scale: pageScale })
                canvas.width = viewport.width
                canvas.height = viewport.height

                // Render PDF page into canvas context
                let renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                }

                // Wait for rendering to finish
                page.render(renderContext).promise.then(() => {
                    setPageRendering(false)

                    if (pageNumPending !== null) {
                        // New page rendering is pending
                        renderPage(pdf)
                        setpageNumPending(null)
                    }
                })
            })
        }
    }

    const downloadData = async (pdf) => {
        const data = await pdf?.getData()
        const blobUrl = window?.URL.createObjectURL(
            new Blob([data], { type: "application/pdf" })
        )
        const fileURL = await pdf?._transport?._networkStream?.source?.url
        const pdfFileName = getPdfFilenameFromUrl(fileURL)
        setdownloadURL({ blob: blobUrl, fileName: pdfFileName })
    }

    const download = (e, url) => {
        e.stopPropagation()
        const a = document.createElement("a")

        if (!a.click) {
            throw new Error('DownloadManager: "a.click()" is not supported.')
        }
        a.href = url.blob + "#pdfjs.action=download"
        a.target = "_parent"
        // Use a.download if available. This increases the likelihood that
        // the file is downloaded instead of opened by another PDF plugin.
        if ("download" in a) {
            a.download = customFileName || url.fileName
        }
        // <a> must be in the document for recent Firefox versions,
        // otherwise .click() is ignored.
        ;(document.body || document.documentElement).append(a)
        a.click()
        a.remove()
    }

    /**
     * If another page rendering in progress, waits until the rendering is
     * finised. Otherwise, executes rendering immediately.
     */

    /**
     * Displays previous page.
     */
    const onPrevPage = (e) => {
        e.stopPropagation()
        if (pageNum <= 1) {
            setpageNum(totalPages)
            return
        }
        setpageNum(pageNum - 1)
    }

    /**
     * Displays next page.
     */
    const onNextPage = (e) => {
        e.stopPropagation()
        if (pageNum >= totalPages) {
            setpageNum(1)
            return
        }

        setpageNum(pageNum + 1)
    }

    const pdfZoomIn = (e) => {
        e.stopPropagation()
        if (pageScale > 3) {
            return
        }
        setPageScale(pageScale + 0.1)
    }

    const pdfZoomOut = (e) => {
        e.stopPropagation()
        if (pageScale <= scale) {
            return
        }
        setPageScale(pageScale - 0.1)
    }
    useEffect(() => {
        const body = document.getElementsByTagName("body")[0]
        if (modalOpen) {
            //hide body scrollbar
            body.style.overflow = "hidden"
            body.style.height = "100%"
            const loadingTask = file ? getDocument({ url: file }) : null
            loadingTask?.promise.then(
                (pdf) => {
                    // you can now use *pdf* here
                    renderPage(pdf)
                    setTotalPages(pdf.numPages)
                    pdf.currentScaleValue = pageScale //zoom in/out
                    downloadData(pdf) // load blob data
                },
                (error) => {
                    console.error(error)
                }
            )
        } else {
            if (pageScale > scale) {
                setPageScale(scale)
            }
            body.style.overflow = "auto"
            body.style.height = "auto"
        }
    }, [pageNum, modalOpen, pageScale])
    const close = () => setModalOpen(false)
    const open = () => setModalOpen(true)
    return (
        <>
            {/*Button Color*/}
            <motion.button
                whileTap={{ scale: 0.9 }}
                onClick={() => (modalOpen ? close() : open())}
                style={{
                    ...buttonStyles.fontStyle,
                    padding: `${buttonStyles.padding + "px"} ${
                        buttonStyles.padding * 2 + "px"
                    }`,
                    backgroundColor: buttonStyles.background,
                    borderRadius: buttonStyles.borderRadius + "px",
                    border: "none",
                    color: buttonStyles.color,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                }}
            >
                {buttonStyles.buttonIcon && (
                    <motion.div
                        style={{
                            width: buttonStyles.pdfIcon?.size + "px",
                            color: buttonStyles.pdfIcon?.color,
                            display: "flex",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: buttonStyles.pdfIcon.icon,
                        }}
                    ></motion.div>
                )}
                {buttonText}
            </motion.button>

            <AnimatePresence
                // Disable any initial animations on children that
                // are present when the component is first rendered
                initial={false}
                // Only render one component at a time.
                // The exiting component will finish its exit
                // animation before entering component is rendered
                exitBeforeEnter={true}
                // Fires when all exiting nodes have completed animating out
                onExitComplete={() => null}
            >
                {modalOpen && file != "" && (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed",
                            inset: 0,
                            zIndex: 11,
                        }}
                    >
                        <motion.div
                            onClick={close}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: "100%",
                                width: "100%",
                                backgroundColor: "rgba(0,0,0,0.7)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <motion.div
                                onClick={(e) => e.stopPropagation()}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 1,
                                        type: "spring",
                                        damping: 25,
                                        stiffness: 500,
                                    },
                                }}
                                exit={{ opacity: 0 }}
                                style={{
                                    width: "auto",
                                    height: "88vh",
                                    margin: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                    overflow: "overlay",
                                    zIndex: 1,
                                }}
                            >
                                <canvas ref={canvasRef}></canvas>
                            </motion.div>

                            {/*top bar*/}
                            <motion.div
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    left: "10px",
                                    right: "10px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "start",
                                }}
                            >
                                <div
                                    style={{
                                        padding: "5px 10px",
                                        backgroundColor: "rgba(0,0,0,0.3)",
                                        borderRadius: "5px",
                                        fontSize:
                                            modalStyles.pageCounter.size + "px",
                                        color: modalStyles.pageCounter.color,
                                        zIndex: 2,
                                    }}
                                >
                                    {pageNum} {"/"}
                                    {totalPages}
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        zIndex: 2,
                                    }}
                                >
                                    <div onClick={close}>
                                        <div
                                            style={{
                                                padding: "5px 10px",
                                                backgroundColor:
                                                    "rgba(0,0,0,0.3)",
                                                width:
                                                    modalStyles.closebtn.size +
                                                    "px",
                                                display: "flex",
                                                color: modalStyles.closebtn
                                                    .color,
                                                cursor: "pointer",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: modalStyles.closebtn
                                                    .closeIcon,
                                            }}
                                        ></div>
                                    </div>
                                    <div onClick={pdfZoomOut}>
                                        <div
                                            style={{
                                                padding: "5px 10px",
                                                backgroundColor:
                                                    "rgba(0,0,0,0.3)",
                                                width:
                                                    modalStyles.zoomControls
                                                        .size + "px",
                                                color: modalStyles.zoomControls
                                                    .color,
                                                cursor: "pointer",
                                                display: "flex",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: modalStyles.zoomControls
                                                    .zoomOutIcon,
                                            }}
                                        ></div>
                                    </div>
                                    <div onClick={pdfZoomIn}>
                                        <div
                                            style={{
                                                padding: "5px 10px",
                                                backgroundColor:
                                                    "rgba(0,0,0,0.3)",
                                                width:
                                                    modalStyles.zoomControls
                                                        .size + "px",
                                                color: modalStyles.zoomControls
                                                    .color,
                                                cursor: "pointer",
                                                display: "flex",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: modalStyles.zoomControls
                                                    .zoomInIcon,
                                            }}
                                        ></div>
                                    </div>
                                    <div
                                        onClick={(e) =>
                                            download(e, downloadURL)
                                        }
                                    >
                                        <div
                                            style={{
                                                padding: "5px 10px",
                                                backgroundColor:
                                                    "rgba(0,0,0,0.3)",
                                                width:
                                                    modalStyles.downloadbtn
                                                        .size + "px",
                                                color: modalStyles.downloadbtn
                                                    .color,
                                                cursor: "pointer",
                                                display: "flex",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: modalStyles.downloadbtn
                                                    .downloadIcon,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </motion.div>

                            {/*navigation*/}
                            <motion.div
                                style={{
                                    position: "absolute",
                                    inset: "10px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    onClick={onPrevPage}
                                    style={{
                                        width: modalStyles.arrows.size + "px",
                                        display: "flex",
                                        color: modalStyles.arrows.color,
                                        cursor: "pointer",
                                        padding: "5px 10px",
                                        background: "rgba(0,0,0,0.3)",
                                        borderRadius: "5px",
                                        zIndex: 2,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: modalStyles.arrows.leftIcon,
                                    }}
                                ></div>
                                <div
                                    onClick={onNextPage}
                                    style={{
                                        width: modalStyles.arrows.size + "px",
                                        display: "flex",
                                        color: modalStyles.arrows.color,
                                        cursor: "pointer",
                                        padding: "5px 10px",
                                        background: "rgba(0,0,0,0.3)",
                                        borderRadius: "5px",
                                        zIndex: 2,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: modalStyles.arrows.rightIcon,
                                    }}
                                ></div>
                            </motion.div>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </>
    )
}

Pdfviewer.defaultProps = {
    file: "",
    pageNumber: 1,
    scale: 1.2,
    buttonText: "View PDF",
    buttonStyles: {
        color: "#000000",
        background: "#ffffff",
        fontStyle: "",
        borderRadius: 25,
        padding: 10,
        buttonIcon: true,
        pdfIcon: { icon: "", size: 0, color: "" },
    },
    modalStyles: {
        pageCounter: { size: 0, color: "" },
        arrows: { leftIcon: "", rightIcon: "", size: 0, color: "" },
        zoomControls: { zoomOutIcon: "", zoomInIcon: "", size: 0, color: "" },
        closebtn: { closeIcon: "", size: 0, color: "" },
        downloadbtn: { downloadIcon: "", size: 0, color: "" },
    },
    customFileName: "Download",
}
addPropertyControls(Pdfviewer, {
    file: {
        title: "File",
        type: ControlType.File,
        allowedFileTypes: ["pdf"],
    },
    pageNumber: {
        title: "Inital Page",
        type: ControlType.Number,
        displayStepper: true,
        min: 1,
    },
    scale: {
        type: ControlType.Number,
        title: "Scale",
        max: 3,
        min: 0.8,
        step: 0.1,
        displayStepper: true,
    },
    buttonText: {
        title: "Button Text",
        type: ControlType.String,
    },

    buttonStyles: {
        type: ControlType.Object,
        title: "Button Styles",

        controls: {
            color: {
                type: ControlType.Color,
                title: "Text Color",
            },
            background: {
                type: ControlType.Color,
                title: "Button color",
            },
            fontStyle: {
                title: "Font Style",
                type: "font",
                controls: "extended",
            },
            borderRadius: {
                type: ControlType.Number,
                title: "Border Radius",
            },
            padding: {
                type: ControlType.Number,
                title: "Padding",
            },
            buttonIcon: {
                type: ControlType.Boolean,
                title: "Button Icon",
                enabledTitle: "Yes",
                disabledTitle: "No",
            },
            pdfIcon: {
                type: ControlType.Object,
                title: "Icon Styles",
                controls: {
                    icon: {
                        type: ControlType.String,
                        title: "Icon",
                        defaultValue: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><polyline points="216 152 184 152 184 208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="208" y1="184" x2="184" y2="184" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><path d="M48,192H64a20,20,0,0,0,0-40H48v56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><path d="M112,152v56h16a28,28,0,0,0,0-56Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><path d="M48,112V40a8,8,0,0,1,8-8h96l56,56v24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><polyline points="152 32 152 88 208 88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg>`,
                    },
                    size: {
                        type: ControlType.Number,
                        title: "Size",
                        defaultValue: 24,
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Color",
                        defaultValue: "red",
                        description:
                            "Note: \n if you want to replace default svg icon, please change 'fill/stroke' property to 'currentColor', then only icon color property will be overide",
                    },
                },
                hidden(props: any) {
                    return props.buttonIcon === false
                },
            },
        },
    },
    modalStyles: {
        type: ControlType.Object,
        title: "Viewer Styles",
        controls: {
            pageCounter: {
                type: ControlType.Object,
                title: "Counter Styles",
                controls: {
                    size: {
                        type: ControlType.Number,
                        title: "Size",
                        defaultValue: 18,
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Color",
                        defaultValue: "#ffffff",
                    },
                },
            },
            arrows: {
                type: ControlType.Object,
                title: "Nav Styles",
                controls: {
                    leftIcon: {
                        type: ControlType.String,
                        title: "Left Icon",
                        defaultValue: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path></svg>`,
                    },
                    rightIcon: {
                        type: ControlType.String,
                        title: "Right Icon",
                        defaultValue: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path></svg>`,
                    },
                    size: {
                        type: ControlType.Number,
                        title: "Icon Size",
                        defaultValue: 40,
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Icon Color",
                        defaultValue: "#ffffff",
                        description:
                            "Note: \n if you want to replace default svg icon, please change 'fill/stroke' property to 'currentColor', then only icon color property will be overide",
                    },
                },
            },
            zoomControls: {
                type: ControlType.Object,
                title: "Zoom Styles",
                controls: {
                    zoomOutIcon: {
                        type: ControlType.String,
                        title: "Zoom-Out Icon",
                        defaultValue: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M152,112a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h64A8,8,0,0,1,152,112Zm77.66,117.66a8,8,0,0,1-11.32,0l-50.06-50.07a88.11,88.11,0,1,1,11.31-11.31l50.07,50.06A8,8,0,0,1,229.66,229.66ZM112,184a72,72,0,1,0-72-72A72.08,72.08,0,0,0,112,184Z"></path></svg>`,
                    },
                    zoomInIcon: {
                        type: ControlType.String,
                        title: "Zoom-In Icon",
                        defaultValue: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M152,112a8,8,0,0,1-8,8H120v24a8,8,0,0,1-16,0V120H80a8,8,0,0,1,0-16h24V80a8,8,0,0,1,16,0v24h24A8,8,0,0,1,152,112Zm77.66,117.66a8,8,0,0,1-11.32,0l-50.06-50.07a88.11,88.11,0,1,1,11.31-11.31l50.07,50.06A8,8,0,0,1,229.66,229.66ZM112,184a72,72,0,1,0-72-72A72.08,72.08,0,0,0,112,184Z"></path></svg>`,
                    },
                    size: {
                        type: ControlType.Number,
                        title: "Icon Size",
                        defaultValue: 40,
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Icon Color",

                        defaultValue: "#ffffff",
                        description:
                            "Note: \n if you want to replace default svg icon, please change 'fill/stroke' property to 'currentColor', then only icon color property will be overide",
                    },
                },
            },
            closebtn: {
                type: ControlType.Object,
                title: "Close Styles",
                controls: {
                    closeIcon: {
                        type: ControlType.String,
                        title: "Close Icon",
                        defaultValue: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>`,
                    },
                    size: {
                        type: ControlType.Number,
                        title: "Icon Size",
                        defaultValue: 40,
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Icon Color",
                        defaultValue: "#ffffff",
                        description:
                            "Note: \n if you want to replace default svg icon, please change 'fill/stroke' property to 'currentColor', then only icon color property will be overide",
                    },
                },
            },
            downloadbtn: {
                type: ControlType.Object,
                title: "Download Styles",
                controls: {
                    downloadIcon: {
                        type: ControlType.String,
                        title: "Download Icon",
                        defaultValue: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z"></path></svg>`,
                    },
                    size: {
                        type: ControlType.Number,
                        title: "Icon Size",
                        defaultValue: 40,
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Icon Color",
                        defaultValue: "#ffffff",
                        description:
                            "Note: \n if you want to replace default svg icon, please change 'fill/stroke' property to 'currentColor', then only icon color property will be overide",
                    },
                },
            },
        },
    },
    customFileName: {
        title: "Download Name",
        type: ControlType.String,
    },
})
